export const menuItems = [
  {
    name: "pricing",
    label: "pricing",
    items: [
      { name: "pricing", label: "our pricing model" },
    ],
  },
  {
    name: "labs",
    label: "labs",
    items: [
      { name: "labs", label: "our labs" },
    ],
  },
  {
    name: "proposal",
    label: "proposal",
    items: [
      { name: "proposal", label: "our system" },
    ],
  },
  {
    name: "teams",
    label: "teams",
    items: [
      {
        name: "members",
        label: "members",
        items: [
          {
            name: "devops",
            label: "devops",
            items: [
              { name: "", label: "fabio palumbo" },
            ],
          },
          {
            name: "",
            label: "junior developers",
            items: [
              {
                name: "",
                label: "junior 2",
              },
            ],
          },
        ],
      },
      {
        name: "portfolio-details",
        label: "portfolio",
        items: [
          { name: "", label: "endava" },
          { name: "", label: "pwc" },
        ],
      },
      { name: "", label: "a better you, every day" },
      { name: "", label: "what's new" },
    ],
  },
];